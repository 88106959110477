import React from 'react';
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';


export const LeftCard = () => {
    return (
        <>
            <Box sx={{ pt: 1 }}>
                <Box
                    component="img"
                    sx={{
                        maxHeight: 350,
                        display: 'block',
                        maxWidth: '90vw',
                        overflow: 'hidden',
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        pb: 5
                    }}
                    src={'/assets/img/agile.webp'}
                    alt={'logocluster'}
                />

                <Typography component='div' sx={{ display: 'inline-block', fontFamily: 'Signika', fontWeight: 'light', fontSize: '12pts', textAlign: 'justify' }} align="center">
                    {' '}<FormattedMessage id="training_text2" defaultMessage="" />
                    {/* Aprovechamos el */}
                    <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '12pts', textAlign: 'justify', color: '#6b3fdf' }} align="center">
                        {' '}<FormattedMessage id="training_text3" defaultMessage="" />
                        {/* {' '} potencial de las nuevas tecnologías, */}
                    </Typography>
                    {' '}<FormattedMessage id="training_text4" defaultMessage="" />
                    {/* {' '}adaptamos nuestros procesos a la cultura ágil de los equipos, generando impacto positivo. Generamos cambio y nos adaptamos a él, */}
                    {/* {' '}  brindando una experiencia satisfactora */}

                    {' '}<FormattedMessage id="training_text6" defaultMessage="" />
                    {/* {' '} para los usuarios. */}
                    <Typography component='div' sx={{ display: 'block', fontFamily: 'Signika', fontWeight: 'light', fontSize: '12pts', textAlign: 'justify' }} align="center">
                        {' '}<FormattedMessage id="training_text7" defaultMessage="" />
                        {/* El pilar fundamental para la excelencia es el aprendizaje, por ello buscamos potenciar los talentos de manera continua. */}
                    </Typography>
                </Typography>
            </Box>
        </>
    )
}

export const RightCard = () => {


    return (
        <>
            {/* <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul> */}

            <Typography component='div' sx={{ flexDirection: 'column', fontFamily: 'Signika', fontWeight: 'light', fontSize: '12pts', textAlign: 'justify' }} align="center">
                <Box sx={{ height: 20 }} />
                <Typography component='div' sx={{ flexDirection: 'column', fontFamily: 'Signika', fontWeight: 'bold', fontSize: '1rem', textAlign: 'justify', color: '#6b3fdf' }} align="center">
                    {' '}<FormattedMessage id="training_text8" defaultMessage="" />
                    {/* TRABAJO DINÁMICO */}
                </Typography>
                <Box sx={{ height: 20 }} />
                <Typography component='div' sx={{ display: 'inline', fontFamily: 'WhitneyIndexBlack', fontWeight: 'semibold', fontSize: '12pts', textAlign: 'justify', color: '#6b3fdf' }} align="center">
                    {' '}<FormattedMessage id="training_text9" defaultMessage="" />
                </Typography>
                <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'light', fontSize: '12pts', textAlign: 'justify' }} align="center">
                    {' '}<FormattedMessage id="training_text10" defaultMessage="" />
                    {/* {' '} Aplicamos soluciones simples a los requermientos cambiantes del mundo real. */}
                </Typography>
                <Box sx={{ height: 60 }} />
                <Typography component='div' sx={{ mb: 1, flexDirection: 'column', fontFamily: 'Signika', fontWeight: 'bold', fontSize: '1rem', textAlign: 'justify', color: '#6b3fdf' }} align="center">
                    {' '}<FormattedMessage id="training_text11" defaultMessage="" />
                    {/* FORMACIÓN, CAPACITACIÓN, ENTRENAMIENTO CONTINUO */}
                </Typography>
                <Box sx={{ height: 20 }} />
                <Typography component='div' sx={{ display: 'inline', fontFamily: 'WhitneyIndexBlack', fontWeight: 'semibold', fontSize: '12pts', textAlign: 'justify', color: '#6b3fdf' }} align="center">
                    {' '}<FormattedMessage id="training_text12" defaultMessage="" />
                </Typography>
                <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'light', fontSize: '12pts', textAlign: 'justify' }} align="center">
                    {' '}<FormattedMessage id="training_text13" defaultMessage="" />
                    {/* {' '} Abrimos nuevos caminos con constante crecimiento profesional y formación en las nuevas tecnologías. */}
                </Typography>
                <Box sx={{ height: 60 }} />
                <Typography component='div' sx={{ mb: 1, flexDirection: 'column', fontFamily: 'Signika', fontWeight: 'bold', fontSize: '1rem', textAlign: 'justify', color: '#6b3fdf' }} align="center">
                    {' '}<FormattedMessage id="training_text14" defaultMessage="" />
                    {/* EXPERIENCIA Y EXCELENCIA */}
                </Typography>
                <Box sx={{ height: 20 }} />
                <Typography component='div' sx={{ display: 'inline', fontFamily: 'WhitneyIndexBlack', fontWeight: 'semibold', fontSize: '12pts', textAlign: 'justify', color: '#6b3fdf' }} align="center">
                    {' '}<FormattedMessage id="training_text15" defaultMessage="" />
                </Typography>
                <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'light', fontSize: '12pts', textAlign: 'justify' }} align="center">
                    {' '}<FormattedMessage id="training_text16" defaultMessage="" />
                    {/* {' '} Años de experiencia avalan nuestra trayectoria y compromiso. */}
                </Typography>
                <Box sx={{ height: 30 }} />
            </Typography>

        </>
    )
}



const Formations = () => {
    const isMobile = window.innerWidth <= 768;
    const cards = [
        {
            "title": "left",
            "component": <LeftCard />
        },
        {
            "title": "right",
            "component": <RightCard />
        },
    ]

    return (
        <>
            <Container disableGutters maxWidth="false" component="main" sx={{ pt: '1.5cm', pb: '0.7cm' }}>
                <Typography
                    component="h2"
                    align="center"

                    sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '24pt', color: '#034a96' }}
                >
                    {' '}<FormattedMessage id="training_text1" defaultMessage="" />
                    {/* FORMACIONES */}
                </Typography>

            </Container>

            <Container maxWidth="xl" sx={{ pt: 2, pb: '1.2cm', }}>
                <Grid container spacing={5} sx={{ justifyContent: 'space-evenly' }} alignItems="flex-center">
                    {cards.map(card => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid
                            item
                            key={card.title}
                            xs={12}
                            md={5}
                            sm={5}
                            sx={{ minHeight: '100%', maxHeight: '100%' }}
                        >
                            <Card sx={{ bgcolor: '#e4effa', py: 1, px: isMobile ? 2 : 8, height: '100%', }}>

                                <CardContent sx={{
                                    height: '100%',

                                }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            pt: 3,
                                            flexGrow: 1
                                        }}
                                    >
                                        {card.component}

                                    </Box>

                                </CardContent>

                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container >
        </>
    )
}

export default Formations