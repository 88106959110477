import { Container } from '@mui/material';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import { Home } from './pages/home/index';

export default function App() {

  return (
    <>
      <Container disableGutters maxWidth="false">
        <header style={{}}>
          <Navbar />
        </header>

        <main >
          <Home />
        </main>

        <footer>
          <Footer />
        </footer>

      </Container>


    </>
  );
}