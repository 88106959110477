import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import HomeIcon from '@mui/icons-material/Home';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Box, Link, styled } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

const MyLink = styled(Link)(({ theme, clicked }) => ({
    textDecoration: 'none',
    color: '#fff',
    transition: 'background 0.3s, width 0.3s',
    padding: '5px 5px',
    width: clicked ? '60vw' : '40px',
    height: clicked ? '40px' : '40px',
    background: clicked ? '#034a9680' : '#75757580',
}));
const Lateral = () => {
    const isMobile = window.innerWidth <= 768;
    const [isVisible, setIsVisible] = useState(false);
    const [linkClicked, setLinkClicked] = useState(6);

    const handleLinkClick = (nro) => {
        if (linkClicked === nro) { setLinkClicked(6); return; }
        setLinkClicked(nro);
        setTimeout(() => {
            setLinkClicked(6);
        }, 2000);
    };

    window.addEventListener('mousemove', mousemove);

    function mousemove(event) {
        if (event.pageX < 60 || event.clientX < 60)
            setIsVisible(true);
        else
            setIsVisible(false);
    }



    const [menuItems] = useState([
        { text: <FormattedMessage id="menu_home" defaultMessage="" />, href: '#hero' },
        { text: <FormattedMessage id="menu_about" defaultMessage="" />, href: '#about' },
        { text: <FormattedMessage id="menu_solutions" defaultMessage="" />, href: '#technologies' },
        { text: <FormattedMessage id="menu_training" defaultMessage="" />, href: '#formations' },
        { text: <FormattedMessage id="menu_contactus" defaultMessage="" />, href: '#calendar' },
    ])


    return (
        <>
            {
                isMobile
                    ?
                    isVisible ?
                        <Box id='lateral' style={{ position: 'fixed', left: 0, bottom: '30%', zIndex: 9999 }}>
                            <div
                                style={{ display: 'flex', flexDirection: 'column', gap: 2, textDecoration: 'none', color: "#fff", textAlign: 'center' }}>
                                <MyLink clicked={linkClicked === 0} onClick={() => handleLinkClick(0)} href={menuItems[0].href} sx={{}} >  {linkClicked !== 0 ? <HomeIcon /> : menuItems[0].text}</MyLink>
                                <MyLink clicked={linkClicked === 1} onClick={() => handleLinkClick(1)} href={menuItems[1].href} sx={{}} >{linkClicked !== 1 ? <VerifiedIcon /> : menuItems[1].text} </MyLink>
                                <MyLink clicked={linkClicked === 2} onClick={() => handleLinkClick(2)} href={menuItems[2].href} sx={{}} >{linkClicked !== 2 ? <MiscellaneousServicesIcon /> : menuItems[2].text}</MyLink>
                                <MyLink clicked={linkClicked === 3} onClick={() => handleLinkClick(3)} href={menuItems[3].href} sx={{}} >{linkClicked !== 3 ? <ModelTrainingIcon /> : menuItems[3].text}</MyLink>
                                <MyLink clicked={linkClicked === 4} onClick={() => handleLinkClick(4)} href={menuItems[4].href} sx={{}} >{linkClicked !== 4 ? <AlternateEmailIcon /> : menuItems[4].text}</MyLink>
                            </div>
                        </Box >
                        :
                        <Box id='lateral' style={{ position: 'fixed', left: 0, top: '50%', zIndex: 9999 }}>
                            <MenuOpenIcon style={{ color: '#757575' }} />
                        </Box>
                    :
                    <></>
            }
        </>
    )
}

export default Lateral