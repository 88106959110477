
import React from 'react';
import { About, Apply, Calendar, Cluster, Formations, Hero, Participations, Technologies, Vision, Work } from './components';
import Experience from './components/experience/Experience';
import Lateral from '../../components/Lateral';

const Home = () => {
    return (
        <>
            <section sx={{ my: 0 }} id="hero">
                <Hero />
            </section>
            <section id="about">
                <About />
            </section>
            <section id="vision">
                <Vision />
            </section>
            <section id="experience">
                <Experience />
            </section>
            <section id="formations">
                <Formations />
            </section>
            <section id="technologies">
                <Technologies />
            </section>
            <section id="participations">
                <Participations />
            </section>
            <section id="calendar">
                <Calendar />
            </section>
            <section id="work">
                <Work />
            </section>
            <section id="apply">
                <Apply />
            </section>
            <section id="cluster">
                <Cluster />
            </section>
            <Lateral />
        </>
    )
}

export default Home; 