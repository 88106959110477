import * as React from 'react';
import { Container, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useScreenSize from '../../../../helpers/useScreenSize';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import WifiOffIcon from '@mui/icons-material/WifiOff';

export function BasicDateCalendar() {
    const [value, setValue] = React.useState(dayjs(Date.now()));
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar minDate={dayjs(Date.now())} value={value} onChange={(newValue) => setValue(newValue)} sx={{ background: '#fff', color: '#000' }} />
        </LocalizationProvider>
    );
}


class Calendly extends React.Component {
    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);
    }


    componentWillUnmount() {
        // whatever you need to cleanup the widgets code
    }

    render() {
        return (
            // <div >
            <div id="schedule_form">
                <div
                    className="calendly-inline-widget"
                    data-url="https://calendly.com/jose-recalde-extrados/30min"
                    style={{ minWidth: '220px', height: isMobile ? '100vh' : 1000, overflow: 'hidden' }}
                />
            </div>
            // </div>
        );
    }
}
const isMobile = window.innerWidth <= 768;
const Calendar = () => {


    const { width } = useScreenSize();

    const [isOnline, setIsOnline] = React.useState(true);

    React.useEffect(() => {
        const handleOnlineStatus = () => {
            setIsOnline(navigator.onLine);
        };

        window.addEventListener('online', handleOnlineStatus);
        window.addEventListener('offline', handleOnlineStatus);

        return () => {
            window.removeEventListener('online', handleOnlineStatus);
            window.removeEventListener('offline', handleOnlineStatus);
        };
    }, []);

    return (
        <>
            <Container disableGutters maxWidth="false" sx={{ height: window.innerWidth > 2000 ? '100vh' : !isMobile ? '100%' : '' }}>

                <Box
                    sx={{
                        position: 'relative',
                        backgroundColor: '#fff',
                        color: '#fff',
                        mt: '0.7cm',
                        pb: '1.1cm',
                        maxHeight: isMobile ? '100%' : 2000,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: `url('/assets/img/bg2.webp')`,
                        height: '100%'
                    }}
                >
                    {/* Increase the priority of the hero background image */}
                    {<img style={{ display: 'none' }} src={'/assets/img/bg2.webp'} alt={'bg2'} />}

                    <Box sx={{ height: '1.8cm', }} />

                    <Grid container style={{ height: '95%' }}>
                        <Grid item xs={12} sm={7} md={7} style={{}}>
                            <Box
                                sx={{
                                    pt: 10,
                                    position: 'relative',
                                    px: { xs: 3, md: 6 },
                                }}
                            >
                                <Typography fontFamily='Montserrat' fontStyle='italic' fontWeight='medium' fontSize={'12pts'} variant="h5" color="inherit" paragraph>
                                    {' '}<FormattedMessage id="calendar_text1" defaultMessage="" />
                                    {/* {'  Si querés tener una'} */}
                                </Typography>
                                <Typography fontFamily='Montserrat' fontStyle='italic' fontWeight='900' fontSize={width > 920 ? 80 : 50} variant="h2" color="inherit" paragraph>
                                    {' '}<FormattedMessage id="calendar_text2" defaultMessage="" />

                                    {/* {'Reunión '} <br />{'online con '} <br />{' nuestros expertos'} */}
                                </Typography>

                                <Typography fontFamily='Montserrat' fontStyle='italic' fontWeight='medium' fontSize={'12pts'} variant="h5" color="inherit" paragraph>
                                    {' '}<FormattedMessage id="calendar_text3" defaultMessage="" />

                                    {/* Selecciona el <b>DIA y HORA </b>que te queden más cómodos y listo! */}
                                </Typography>

                                <Typography fontFamily='Signika' fontWeight='light' fontSize={'12pts'} variant="h5" color="inherit" paragraph>
                                    {' '}<FormattedMessage id="calendar_text4" defaultMessage="" />

                                    {/* {'  En la reunión nos podés contar tus inquietudes, proyecto, problema etc. y buscaremos la mejor solución para poder ayudarte'} */}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={5} md={5}
                            sx={{
                                p: 2,
                                flexGrow: 1,
                            }}>
                            {isOnline
                                ?
                                <Calendly style={{}} />
                                :
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <WifiOffIcon style={{ fontSize: 50 }} />
                                    <p style={{ fontSize: 9 }}> Offline</p>


                                </Box>
                            }
                            {/* <BasicDateCalendar /> */}
                        </Grid>


                    </Grid>

                </Box >
            </Container >
        </>
    )
}

export default Calendar; 