import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#034a96',
            mainGradient: 'linear-gradient(to right, #e4effa, #034a96)',
        },
        secondary: {
            main: '#6b3fdf',
            mainGradient: 'linear-gradient(to right, #e4effa, #6b3fdf)',
        },
        tertiary: {
            main: '#034a9670',
        },
        error: {
            main: '#ff4b4b',
        },
        errorbg: {
            main: '#ff4b4b90',
        },
        success: {
            main: '#008A63',
        },
        successbg: {
            main: '#008A6390',
        },
        warning: {
            main: '#ed6c02',
        },
        transparent: {
            main: 'transparent'
        }
    },

});