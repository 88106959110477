import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import useScreenSize from '../../../../helpers/useScreenSize';
import { FormattedMessage } from 'react-intl';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', // Centrar verticalmente
    overflow: 'hidden', // Para evitar que la imagen desborde
    '& img': {
        width: 60,
        height: 60,
    },
}));

const isMobile = window.innerWidth <= 768;
const Technologies = () => {

    const { width } = useScreenSize();

    const technologies = [
        {
            id: 1,
            name: 'Flutter',
            imgUrl: '/assets/img/flutter.png'
        },
        {
            id: 2,
            name: 'Ionic',
            imgUrl: '/assets/img/ionic.png'
        },
        {
            id: 3,
            name: '.Net',
            imgUrl: '/assets/img/dotnet.png'
        },
        {
            id: 4,
            name: 'Angular',
            imgUrl: '/assets/img/angular.png'

        },

        {
            id: 5,
            name: 'SQL Server',
            imgUrl: '/assets/img/sqlserver.png'
        },
        {
            id: 6,
            name: 'Oracle',
            imgUrl: '/assets/img/oracle.webp'

        },
        {
            id: 7,
            name: 'Swift',
            imgUrl: '/assets/img/swift.png'
        },
        {
            id: 8,
            name: 'PHP',
            imgUrl: '/assets/img/php.webp'
        },
        {
            id: 9,
            name: 'Node',
            imgUrl: '/assets/img/node.png'
        },
        {
            id: 10,
            name: <> <p style={{}}>REACT NATIVE</p><p style={{}}>REACT</p></>,
            imgUrl: '/assets/img/react.png'
        },
    ]

    return (
        <>
            <Container disableGutters maxWidth="false" sx={{ pt: '0.7cm', pb: '1.1cm' }}>
                <Box
                    sx={{
                        position: 'relative',
                        backgroundColor: '#fff',
                        color: '#fff',
                        mt: '0.7cm',
                        pb: '1.1cm',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: `url('/assets/img/bg2.webp')`,
                    }}
                >
                    {/* Increase the priority of the hero background image */}
                    {<img style={{ display: 'none' }} src={'/assets/img/bg2.webp'} alt={'bg2'} />}

                    <Box sx={{ display: 'flex', px: 5, flexDirection: 'column', gap: '1.1cm' }}>

                        <Typography component='div' sx={{ flexDirection: 'column', textAlign: 'center' }} align="center">
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.1cm' }}>
                                <Typography component='div' sx={{ pt: 5, flexDirection: 'column', fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: width > 920 ? '2.4rem' : '1.4rem', textAlign: 'center' }} align="center">
                                    {' '}<FormattedMessage id="services_text1" defaultMessage="" />
                                    {/* SERVICIOS Y TECNOLOGÍAS */}
                                </Typography>

                                <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'light', fontSize: '1.2rem', textAlign: 'center' }} align="center">
                                    {' '}<FormattedMessage id="services_text2" defaultMessage="" />
                                    {/* Trabajamos de manera ágil, con METODOLOGÍA SCRUM DURANTE EL DESARROLLO. Esto ayuda a resolver la definición de los problemas complejos, aportando valor de forma incremental. */}
                                </Typography>

                                <Typography component='div' sx={{ flexDirection: 'column', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '1.5rem', textAlign: 'center' }} align="center">
                                    {' '}<FormattedMessage id="services_text3" defaultMessage="" />
                                    {/* TECNOLOGÍA QUE USAMOS */}
                                </Typography>
                            </Box>
                        </Typography>

                        <Grid container sx={{}} spacing={{ xs: 4, md: 4 }} columns={{ xs: 8, sm: 8, md: 12 }}>
                            {technologies.map((_, index) => (
                                <Grid item xs={4} md={2.4} key={index} style={{ display: 'flex', justifyContent: 'center', }}>
                                    <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'center', gap: isMobile ? 0 : 2 }}>
                                        <Item sx={{ borderRadius: '25%', }}>
                                            <img style={{ filter: 'grayscale(1)', }} src={_.imgUrl} alt={_.name} />
                                        </Item>
                                        <Typography component='div' sx={{ pl: 1, pt: _.id === 10 ? 0 : 2, flexDirection: 'column', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: _.id === 9 || _.id === 10 ? '1rem' : '1.2rem', textAlign: 'center', width: isMobile ? '100%' : '50%' }} align="center">
                                            {_.id === 10 ? _.name : _.name.toUpperCase()}
                                        </Typography>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                </Box>
            </Container >
        </>
    )
};

export default Technologies; 