import { Box, Container, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ExperienceCard from './components/ExperienceCard';


const Experience = () => {

    const cases = [
        {
            id: 1,
            title: 'experience_text5',
            description: 'experience_text6',
            urlImg2: '/assets/img/divider.svg',
            urlImg: '/assets/img/exp_ecomerce3.webp'
        },
        {
            id: 2,
            title: 'experience_text7',
            description: 'experience_text8',
            urlImg2: '/assets/img/divider.svg',
            urlImg: '/assets/img/exp_travel3.webp',
        },
        {
            id: 3,
            title: 'experience_text9',
            description: 'experience_text10',
            urlImg2: '/assets/img/divider.svg',
            urlImg: '/assets/img/exp_goverment2.webp',
        },
        {
            id: 4,
            title: 'experience_text11',
            description: 'experience_text12',
            urlImg2: '/assets/img/divider.svg',
            urlImg: '/assets/img/exp_salud3.webp'
        }, {
            id: 5,
            title: 'experience_text13',
            description: 'experience_text14',
            urlImg2: '/assets/img/divider.svg',
            urlImg: '/assets/img/exp_telemedicina3.webp'
        },
        {
            id: 6,
            title: 'experience_text15',
            description: 'experience_text16',
            urlImg2: '/assets/img/divider.svg',
            urlImg: '/assets/img/exp_electricista.webp',
        },
        {
            id: 7,
            title: 'experience_text17',
            description: 'experience_text18',
            urlImg2: '/assets/img/divider.svg',
            urlImg: '/assets/img/exp_autogestion2.webp',
        },
        {
            id: 8,
            title: 'experience_text19',
            description: 'experience_text20',
            urlImg2: '/assets/img/divider.svg',
            urlImg: '/assets/img/exp_mineria.webp',
        },
    ];
    const isMobile = window.innerWidth <= 768;

    return (
        <>
            <Container disableGutters maxWidth="false" sx={{ p: '5%', bgcolor: '#e4effa', display: 'flex', mt: 5 }}>
                <Grid container   >
                    <Grid item xs={12} md={6} sx={{ px: '2%', display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', }}>
                        <Box sx={{ display: 'flex', py: '5%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '0.7cm' }}>
                            <Typography
                                component="h2"
                                align="center"
                                gutterBottom
                                sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '24pt', color: '#034a96', textTransform: 'uppercase', }}
                            >
                                <FormattedMessage id="experience_text1" defaultMessage="" />
                                {/* EXPERIENCIA */}
                            </Typography>

                            <Box sx={{ width: '100%', textAlign: 'center', py: '5%', px: isMobile ? 1 : '2%' }}>
                                <Typography component='div' sx={{ display: 'inline-block', fontFamily: 'Signika', fontWeight: 'light', fontSize: '1.2rem', textAlign: 'left', }} >
                                    <FormattedMessage id="experience_text2" defaultMessage="" />
                                    {/* Tenemos experiencia en 5 países de latinoamérica:{' '} */}
                                    {'  '}
                                    <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '1.2rem', textAlign: 'left', color: '#6b3fdf', }} >
                                        <FormattedMessage id="experience_text3" defaultMessage="" />
                                        {/* Argentina, Chile, Perú, México y Estados Unidos. */}
                                    </Typography>
                                    {' '}<FormattedMessage id="experience_text4" defaultMessage="" />
                                    {/* {' '}Además algunos de nuestros casos de éxio son los siguientes: */}
                                </Typography>
                            </Box>

                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'start', alignContent: 'center' }}>
                            {/* <Grid item xs={0} md={2}></Grid> */}
                            <Grid item xs={12} md={10} >
                                <Box sx={{ display: 'flex', p: '2%', flexDirection: 'column', justifyContent: 'space-around', alignContent: 'space-around', gap: 5 }}>

                                    <Typography
                                        component="h2"
                                        align="left"

                                        sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '10pt', color: '#034a96', textTransform: 'uppercase', }}
                                    >
                                        <FormattedMessage id="experience_text5" defaultMessage="" />
                                    </Typography>
                                    <Typography
                                        component="h2"
                                        align="left"

                                        sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '10pt', color: '#034a96', textTransform: 'uppercase', }}
                                    >
                                        <FormattedMessage id="experience_text7" defaultMessage="" />
                                    </Typography>
                                    <Typography
                                        component="h2"
                                        align="left"

                                        sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '10pt', color: '#034a96', textTransform: 'uppercase', }}
                                    >
                                        <FormattedMessage id="experience_text9" defaultMessage="" />
                                    </Typography>
                                    <Typography
                                        component="h2"
                                        align="left"

                                        sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '10pt', color: '#034a96', textTransform: 'uppercase', }}
                                    >
                                        <FormattedMessage id="experience_text11" defaultMessage="" />
                                    </Typography>
                                    <Typography
                                        component="h2"
                                        align="left"

                                        sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '10pt', color: '#034a96', textTransform: 'uppercase', }}
                                    >
                                        <FormattedMessage id="experience_text13" defaultMessage="" />
                                    </Typography>
                                    <Typography
                                        component="h2"
                                        align="left"

                                        sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '10pt', color: '#034a96', textTransform: 'uppercase', }}
                                    >
                                        <FormattedMessage id="experience_text15" defaultMessage="" />
                                    </Typography>
                                    <Typography
                                        component="h2"
                                        align="left"

                                        sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '10pt', color: '#034a96', textTransform: 'uppercase', }}
                                    >
                                        <FormattedMessage id="experience_text17" defaultMessage="" />
                                    </Typography>
                                    <Typography
                                        component="h2"
                                        align="left"

                                        sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '10pt', color: '#034a96', textTransform: 'uppercase', }}
                                    >
                                        <FormattedMessage id="experience_text19" defaultMessage="" />
                                    </Typography>
                                </Box>
                            </Grid>
                            {/* <Grid item xs={0} md={2}></Grid> */}
                        </Box>
                    </Grid>



                    <Grid item xs={12} md={6}>
                        {/* <Box sx={{ display: 'flex', p: '5%', flexDirection: 'column', justifyContent: 'center', gap: '1.1cm' }}> */}

                        <img style={{ display: 'flex', width: '100%', height: '100%', objectFit: 'contain' }} src={'/assets/img/mapa.png'} alt={'mapa'} />

                        {/* </Box> */}
                    </Grid>



                    <Grid container spacing={2} sx={{ pt: '5%' }}>
                        {cases.map((caseItem) => (
                            <Grid key={caseItem.id} item sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', p: isMobile ? 0 : 2 }} xs={12} sm={6} >
                                <ExperienceCard caseItem={caseItem} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>


            </Container >
        </>
    )
}

export default Experience