import { Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';



export const MisionCard = () => {
    return (
        <>
            <Typography component='div' sx={{ display: 'inline-block', textAlign: 'center', fontFamily: 'Signika', fontWeight: 'light', fontSize: '10pts', }} >
                <FormattedMessage id="vision_text2" defaultMessage="We want to generate" />
                <Typography component='div' sx={{ display: 'inline', textAlign: 'center', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '10pts', color: '#6b3fdf' }} >
                    <FormattedMessage id="vision_text3" defaultMessage="a positive impact" />  {' '}
                </Typography>
                <FormattedMessage id="vision_text4" defaultMessage="in the" />  {' '}
                <Typography component='div' sx={{ display: 'inline', textAlign: 'center', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '10pts', color: '#6b3fdf' }} >
                    <FormattedMessage id="vision_text5" defaultMessage="processes, projects, and systems" />  {' '}
                </Typography>
                <FormattedMessage id="vision_text6" defaultMessage="of our clients, through our vast experience." />  {' '}
            </Typography>
            <Typography component='div' sx={{ textAlign: 'center', fontFamily: 'Signika', fontWeight: 'light', fontSize: '10pts', }} >
                <FormattedMessage id="vision_text7" defaultMessage="Based on innovation, adaptation to new methodologies and technological advances." />
            </Typography>
        </>
    )
}

export const VisionCard = () => {
    return (
        <>
            <Typography component='div' sx={{ display: 'inline-block', textAlign: 'center', fontFamily: 'Signika', fontWeight: 'light', fontSize: '10pts', }} >
                <FormattedMessage id="vision_text11" defaultMessage="" />
                <Typography component='div' sx={{ display: 'inline', textAlign: 'center', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '10pts', color: '#6b3fdf' }} >
                    {' '} <FormattedMessage id="vision_text12" defaultMessage="" />
                </Typography>
                {' '}<FormattedMessage id="vision_text13" defaultMessage="" />
            </Typography>
        </>
    )
}

export const ValoresCard = () => {
    return (
        <>
            <Typography sx={{ display: 'inline-block', textAlign: 'center', fontFamily: 'Signika', fontWeight: 'light', fontSize: '10pts', }} >
                <FormattedMessage id="vision_text9" defaultMessage="" />
            </Typography>
        </>
    )
}

const Vision = () => {
    const cards = [
        {
            "image": "/assets/img/hands.webp",
            "title": <FormattedMessage id="vision_text1" defaultMessage="MISSION" />,
            "content": {
                component: <MisionCard />
            },
        },
        {
            "image": "/assets/img/office.webp",
            "title": <FormattedMessage id="vision_text8" defaultMessage="VALUES" />,
            "content": {
                component: <ValoresCard />
            },
        },
        {
            "image": "/assets/img/screen.webp",
            "title": <FormattedMessage id="vision_text10" defaultMessage="VISION" />,
            "content": {
                component: <VisionCard />
            },
        },

    ];

    return (
        <>
            <Container disableGutters maxWidth="false" component="main" sx={{ pt: 8, pb: 6, bgcolor: '#e4effa' }}>
                <Grid sx={{ pt: 2, pb: 2, bgcolor: '#e4effa' }} container spacing={0}>
                    {cards.map((card, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 1,
                        }}>


                            <CardMedia
                                component="img"
                                sx={{
                                    // padding: "1em 1em 0 1em", objectFit: "cover",
                                }}
                                alt={"vision"}
                                image={card?.image || ''}
                            />

                            <Card
                                sx={{ height: '55%', display: 'flex', flexDirection: 'column', border: 0, boxShadow: 'none', }}
                            >

                                <CardContent sx={{
                                    bgcolor: '#e4effa', p: 5, flexGrow: 1
                                }}>
                                    <Typography sx={{ textAlign: 'center', fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '16pts', color: '#034a96' }} gutterBottom variant="h5" component="h2">
                                        {card.title}
                                    </Typography>

                                    {card.content.component}

                                </CardContent>

                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container >
        </>
    )
}

export default Vision