import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, TextField, Typography, styled } from '@mui/material';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'; // Importa las funciones de Firebase para el almacenamiento
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormattedMessage } from 'react-intl';
import storage, { db } from '../../../../config/firebaseConfig';
import { collection, doc, setDoc, getDocs } from 'firebase/firestore';

const HiddenInput = styled('input')({
    // Para ocultar el botón de selección de archivo y el texto "Ningún archivo seleccionado"
    display: 'none',
});

const Apply = () => {


    // const formData = new FormData();
    const [sendFile, setSendFile] = useState(null);
    const [fileName, setFileName] = useState('');

    const hiddenInputRef = React.createRef();

    const handleOpenFileDialog = () => {
        // Para simular el clic en el input oculto
        hiddenInputRef.current.click();
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setSendFile(file);
        setFileName(file.name);
    };


    const handleSendEmail = async (fileUrl, fileName) => {
        try {
            const response = await fetch("https://formsubmit.co/ajax/88e2657e15ef1e62b64876577baac609", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    _template: 'table',
                    _subject: 'Nuevo CV',
                    name: fileName,
                    message: fileUrl,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data); // Hacer algo con la respuesta JSON aquí
            } else {
                console.error('Error al realizar la solicitud.');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const handleSendCv = async (e) => {
        e.preventDefault();
        //
        if (sendFile) {
            try {
                // throw new Error('error');
                const storageRef = ref(storage, 'cvs/' + sendFile.name);

                await uploadBytes(storageRef, sendFile);

                const url = await getDownloadURL(storageRef);

                toast.success('CV subido con éxito.', {
                    position: toast.POSITION.TOP_CENTER
                });


                const nombrearchivo = sendFile.name;
                const docRef = doc(db, 'cvs', nombrearchivo);
                await setDoc(docRef, { nombre: nombrearchivo, url: url });
                handleSendEmail(url, nombrearchivo);

            } catch (error) {
                toast.error('Error subiendo CV', {
                    position: toast.POSITION.TOP_CENTER
                });
                console.error(':::ERROR::::', error);
            } finally {
                // Para limpiar el estado después de subir el archivo
                setSendFile(null);
                setFileName('');
            }

        }
    }

    const [docList, setDocList] = useState([]);

    const getData = async () => {
        try {
            const docsRef = collection(db, 'cvs');
            const querySnapshot = await getDocs(docsRef);
            const data = querySnapshot.docs.map(doc => doc.data());
            setDocList(data)
        } catch (error) {
            console.error('Error al descargar el PDF:', error);
        }
    }

    useEffect(() => {
        getData();
        return () => {
            setDocList([]);
        }
    }, [fileName]);


    return (
        <>
            <Container disableGutters maxWidth="false" sx={{ p: 2, bgcolor: '#e4effa', display: 'flex', mb: '1.2cm' }}>

                <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', width: '100%', gap: '0.7cm' }}>
                    <Typography
                        component="h2"
                        align="center"
                        sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '2.4rem', color: '#034a96', }}
                    >
                        {' '}<FormattedMessage id="apply_text1" defaultMessage="" />
                        {/* ¡POSTULATE ACÁ YA MISMO! */}
                    </Typography>

                    <Grid container spacing={2} component="form" onSubmit={e => handleSendCv(e)} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={12} md={3} sm={12} />
                        <Grid item xs={12} md={4} sm={12} >
                            <TextField
                                label={<FormattedMessage id="apply_text2" defaultMessage="" />}
                                value={fileName || ''}
                                variant="outlined"
                                autoComplete="off"
                                onClick={handleOpenFileDialog}
                                InputProps={{ readOnly: true, }}
                                sx={{ bgcolor: '#fff', minWidth: '100%', }}
                            />
                            <HiddenInput
                                type="file"
                                ref={hiddenInputRef}
                                accept=".pdf, .doc, .docx"
                                onChange={handleFileInputChange} />
                        </Grid>

                        <Grid item xs={12} md={2} sm={12} >
                            <Button variant="contained" type='submit' sx={{ borderRadius: 25, background: '#6b3fdf', py: 2, px: 10, fontFamily: 'Signika', fontWeight: 'medium', fontSize: 10, whiteSpace: 'nowrap' }}>
                                {/* {' '}  ME SUMO! {' '} */}
                                {' '}<FormattedMessage id="apply_text3" defaultMessage="" />
                            </Button>

                            <input type='hidden' name='_next' value='http://localhost:3000/' />
                            <input type='hidden' name='_captcha' value='false' />

                        </Grid>

                    </Grid>

                    <Box sx={{ width: '70%', display: 'flex', alignSelf: 'center', textAlign: 'center', pb: 5 }}>
                        <Typography component='div' sx={{ display: 'inline-block', fontFamily: 'Signika', fontWeight: 'light', fontSize: '1.2rem', textAlign: 'justify' }} align="center">
                            {' '}<FormattedMessage id="apply_text4" defaultMessage="" />
                            {/* Constantemente estamos buscando nuevas personas para que se sumen a EXTRADOS para diferentes tecnologías. */}
                            <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '1.2rem', textAlign: 'justify', color: '#6b3fdf' }} align="center">
                                {' '}<FormattedMessage id="apply_text5" defaultMessage="" />
                                {/* {' '}Envianos tu CV y pronto nos vamos a contactar! */}
                            </Typography>
                        </Typography>
                    </Box>
                </Box>
                <ToastContainer />
            </Container>
        </>
    )
}

export default Apply