import { Card, CardContent, CardMedia, Checkbox, Grid, Stack, Typography } from '@mui/material'
import React, { useState } from 'react';
import { indigo } from '@mui/material/colors';
import { FormattedMessage } from 'react-intl'

const ExperienceCard = ({ caseItem }) => {

    const isMobile = window.innerWidth <= 768;
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [checked, setChecked] = useState(true);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const [showContent, setShowContent] = useState(true)

    const toggleContent = () => {
        setShowContent(!showContent)
    }

    return (

        <>

            <Grid item xs={12} md={6} display='none'>
                <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    {...label}
                    defaultChecked
                    sx={{
                        color: indigo[100],
                        '&.Mui-checked': {
                            color: indigo[800],
                        },
                    }}
                />
            </Grid>

            <Card
                component="a"
                sx={{ height: isMobile ? '80vh' : '50vh', width: isMobile ? '100%' : '40vw', display: 'flex', flexDirection: 'column', border: 0, boxShadow: 'none', }}
                style={{ position: "relative" }}
            >
                <CardMedia
                    component="img"
                    sx={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover'
                    }}
                    // src={'/assets/img/divider.svg'}
                    src={checked ? caseItem.urlImg : caseItem.urlImg2}

                />
                <CardContent
                    style={{
                        // filter: 'grayscale(1)',
                        //top: "20%",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignContent: 'center',
                        position: "absolute",
                        width: "100%",
                        height: '100%',
                        textAlign: "center",
                        color: "black",
                        backgroundColor: "none",
                        fontFamily: "Comic Sans MS",

                    }}
                    onMouseEnter={toggleContent}
                    onMouseLeave={toggleContent}
                    onClick={isMobile ? toggleContent : () => { }}
                    sx={{
                        transition: 'bgcolor 0.9s ease',
                        bgcolor: showContent ? '' : '#00000099',
                        width: '100%',
                    }}>
                    <Typography
                        style={{
                            position: 'absolute',

                            margin: 'auto',
                            transition: 'visibility 0.9s, opacity 0.9s linear',
                            visibility: showContent ? 'visible' : 'hidden',
                            opacity: showContent ? 1 : 0,
                        }}
                        sx={{
                            display: 'inline-block', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 5, textAlign: 'center', fontFamily: 'Signika', fontWeight: 'bold', fontSize: '14pt', color: '#fff', textTransform: 'uppercase', bgcolor: '#00000090', width: '100%',
                        }} gutterBottom component="h2" lineHeight={2} >
                        <FormattedMessage id={caseItem.title} defaultMessage="" />
                    </Typography>

                    <Typography
                        style={{
                            position: 'absolute',
                            marginInline: 20,
                            marginBlock: 'auto',
                            transition: 'visibility 0.9s, opacity 0.9s linear',
                            visibility: !showContent ? 'visible' : 'hidden',
                            opacity: !showContent ? 1 : 0,
                        }}
                        sx={{
                            display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 40, textAlign: 'justify', fontFamily: 'Signika', fontWeight: 'italic', fontSize: '12pt', mb: 3, color: '#fff'
                        }} gutterBottom component="h2" lineHeight={2} >
                        <FormattedMessage id={caseItem.description} defaultMessage="" />
                    </Typography>
                    {/* {
                        showContent
                            ?
                            <Typography
                                style={{
                                    transition: 'visibility 0.9s, opacity 0.9s linear',
                                    visibility: showContent ? 'visible' : 'hidden',
                                    opacity: showContent ? 1 : 0,
                                }}
                                sx={{
                                    display: 'inline-block', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 5, textAlign: 'center', fontFamily: 'Signika', fontWeight: 'bold', fontSize: '14pt', color: '#fff', textTransform: 'uppercase', bgcolor: '#00000090', width: '100%',
                                }} gutterBottom component="h2" lineHeight={isMobile ? 1.5 : 2} >
                                <FormattedMessage id={caseItem.title} defaultMessage="" />
                            </Typography>
                            :
                            <Typography
                                style={{
                                    transition: 'visibility 0.9s, opacity 0.9s linear',
                                    visibility: !showContent ? 'visible' : 'hidden',
                                    opacity: !showContent ? 1 : 0,
                                }}
                                sx={{
                                    display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 20, textAlign: 'justify', fontFamily: 'Signika', fontWeight: 'italic', fontSize: isMobile ? '10pt' : '12pt', mb: 3, color: '#fff'
                                }} gutterBottom component="h2" lineHeight={isMobile ? 1.5 : 2} >
                                <FormattedMessage id={caseItem.description} defaultMessage="" />
                            </Typography>
                    } */}
                </CardContent>

            </Card>
        </>



    )
}

export default ExperienceCard