import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const Cluster = () => {
    const isMobile = window.innerWidth <= 768;

    return (
        <>
            <Container disableGutters maxWidth="false" component="main" sx={{ pt: '1.8cm', pb: '1.8cm', bgcolor: '#018D96', mb: '1.2cm' }}>
                <Box
                    sx={{
                        position: 'relative',
                        backgroundColor: '#018d96',
                        color: '#fff',
                        mt: '0.7cm',
                        pb: '1.1cm',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',

                    }}
                >
                    <Grid container >
                        <Grid item xs={1} md={3} />
                        <Grid item xs={10} md={6}>
                            <Box
                                sx={{
                                    position: 'relative',
                                    px: { xs: 3, md: 6 },
                                }}
                            >
                                <Typography sx={{ textAlign: 'center' }} fontFamily='Montserrat' fontStyle='italic' fontWeight='medium' fontSize={!isMobile ? '24pt' : '12pt'} variant="h5" color="inherit" paragraph>
                                    {' '}<FormattedMessage id="cluster_text1" defaultMessage="" />
                                    {/* {'Formamos parte de'} */}
                                </Typography>

                                <Box
                                    component="img"
                                    sx={{
                                        maxHeight: 350,
                                        display: 'block',
                                        maxWidth: '100vw',
                                        overflow: 'hidden',
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        pb: 0
                                    }}
                                    src={'/assets/img/cluster.logo.svg'}
                                    alt={'logocluster'}
                                />

                                <Typography sx={{ textAlign: 'center' }} fontFamily='Montserrat' fontStyle='italic' fontWeight='medium' fontSize={!isMobile ? '20pt' : '10pt'} variant="h5" color="inherit" paragraph>
                                    {' '}<FormattedMessage id="cluster_text2" defaultMessage="" />

                                    {/* {'dónde completamos esfuerzos para desarrollar y ofrecer soluciones tecnológicas de nivel internacional junto a otras empresas.'} */}
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item xs={1} md={3} />
                    </Grid>

                </Box >
            </Container>
        </>
    )
};

export default Cluster; 