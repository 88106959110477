import NavigationIcon from '@mui/icons-material/Navigation';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Container, Fab, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { useContext } from 'react';
// import { FormattedMessage } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { langContext } from '../../../../context/langContext';

const Hero = () => {
    const isMobile = window.innerWidth <= 768;
    const { locale } = useContext(langContext);

    function openWhatsApp() {
        const phoneNumber = '5493512597577'; // Número de teléfono
        const message = encodeURIComponent('*Hola: me gustaría solicitar info sobre...*'); // Mensaje

        const isMobileDev = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        let url = '';
        if (isMobile || isMobileDev) {
            url = `whatsapp://send?phone=${phoneNumber}&text=${message}`;
        } else {
            url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
        }

        window.open(url, '_blank');
    }

    React.useEffect(() => {


        const allVideos = document.querySelectorAll('video[src^="/assets/videos/"]');
        const fluidEl = document.body;

        // Function to calculate and save aspect ratio for each video
        const calculateAspectRatio = (video) => {
            const aspectRatio = video.offsetHeight / video.offsetWidth;
            video.dataset.aspectRatio = aspectRatio;

            // Remove the hard-coded width/height
            video.removeAttribute('height');
            video.removeAttribute('width');
        };

        // Function to resize videos based on their aspect ratio
        const resizeVideos = () => {
            const newWidth = fluidEl.clientWidth;

            allVideos.forEach((video) => {
                const aspectRatio = parseFloat(video.dataset.aspectRatio);
                video.style.width = `${newWidth}px`;
                video.style.height = `${newWidth * aspectRatio}px`;
            });
        };

        const handleResize = () => {
            resizeVideos();
        };


        // Iterate through all videos on initial load
        allVideos.forEach((video) => {
            calculateAspectRatio(video);
        });

        // // Resize videos on window resize
        window.addEventListener('resize', handleResize);
        // // Initial resize to fix all videos on page load
        // resizeVideos();

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []); // Empty dependency array to run the effect only once on component mount



    return (
        <>
            <Container disableGutters maxWidth="false">
                <Box
                    sx={{
                        position: 'relative',
                        backgroundColor: '#000',
                        color: '#fff',
                        width: '100%',
                        height: 'auto',
                        mt: 5,
                        pb: 5,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        // backgroundImage: `url('/assets/img/bghero.webp')`,
                    }}
                >
                    {/* Increase the priority of the hero background image */}
                    {/* {<img style={{ display: 'none' }} src={'/assets/img/bghero.webp'} alt={'bghero'} />} */}


                    {/*                     
                     <Grid container
                        sx={{ height: 'auto', width: '100%', display: 'flex', flexDirection: !isMobile ? 'row' : 'column-reverse', justifyContent: 'space-around', alignItems: 'center', mb: isMobile ? 0 : 20, pt: 5, }}
                    >
                        {
                            !isMobile
                            &&
                            <Grid item xs={12} sm={2.5} component="img"
                                src={'/assets/img/logo2.webp'}
                                alt='Extrados'
                                style={{
                                    mt: 35,
                                    maxWidth: '100%',
                                    height: 'auto',
                                    width: isMobile ? '100%' : '40%',
                                    objectFit: 'contain',
                                    zIndex: 999,
                                }}
                                sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                            />

                        }

                        {
                            isMobile &&

                            <Grid item xs={12} sm={10} style={{
                                maxWidth: '100%',
                                height: 'auto',
                                width: '100%',
                                zIndex: 999,
                            }}  >

                                <Menu />
                            </Grid>
                        }
                    </Grid> 
                    */}


                    <Stack
                        // sx={{ pt: '1cm', pb: '1.4cm', }}
                        direction="column"
                        spacing={isMobile ? 1 : 12}
                        justifyContent='space-evenly'
                        alignItems='center'
                    >


                        <Grid container >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    display: 'flex',
                                    top: 0,
                                    bottom: 0,
                                    right: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    minWidth: '100%',
                                    minHeight: '100%',
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                    // backgroundColor: 'rgba(0,0,0,0)',
                                    // boxShadow: "none"
                                }}
                            >
                                <video style={{ position: 'absolute', bottom: 0, top: 0, width: '100%', height: '100%', }} autoPlay muted loop src={locale === 'en-US' ? '/assets/videos/extradoseng.webm' : '/assets/videos/extradosesp.webm'} />
                            </Box>

                        </Grid>


                        <Grid container >
                            <Grid item xs={12} sm={8} >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        p: { xs: 1, md: 4 },
                                        pr: { md: 0 },
                                        height: 'auto',
                                    }}
                                >

                                    {/* 
                                    <Typography sx={{ paddingBottom: 5 }} fontFamily='Montserrat' fontStyle='italic' fontWeight='medium' fontSize={'16pt'} color="inherit" gutterBottom>
                                        <FormattedMessage id="hero_title1" defaultMessage="We provide services for the" />
                                    </Typography>

                                    <div style={{ fontSize: 'larger', textAlign: 'justify' }}>
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                whitespace: 'break-word',
                                                wordBreak: 'break-all',
                                                textAlign: 'justify',
                                                lineHeight: .9,
                                                letterSpacing: locale === 'en-US' && !isMobile ? '0.3em' : locale === 'en-US' && isMobile ? '0.3em' : locale !== 'en-US' && isMobile ? '0' : '0.2em',
                                                // wordSpacing: '0.25em',
                                            }}
                                            fontFamily='Montserrat' fontStyle='italic' fontWeight='900'
                                            fontSize={locale === 'en-US' && !isMobile ? '5em' : locale === 'en-US' && isMobile ? '2.1em' : locale !== 'en-US' && isMobile ? '1.2em' : '2.3em'}
                                            color="inherit" paragraph>
                                            <FormattedMessage style={{ marginY: 0 }} id="hero_title2" defaultMessage="Digital" />
                                        </Typography>


                                        <Typography sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            wordBreak: 'break-all',
                                            whitespace: 'break-word',
                                            textAlign: 'justify',
                                            letterSpacing: locale === 'en-US' && !isMobile ? '0.18em' : locale === 'en-US' && isMobile ? '0.2em' : locale !== 'en-US' && isMobile ? '0.3em' : '0.18em',
                                            lineHeight: 1,
                                            // wordSpacing: '0.25em',
                                        }}
                                            fontFamily='Montserrat' fontStyle='italic' fontWeight='900'
                                            fontSize={locale === 'en-US' && !isMobile ? '2.5em' : locale === 'en-US' && isMobile ? '1em' : locale !== 'en-US' && isMobile ? '2em' : '5.6em'}
                                            color="inherit" paragraph>
                                            <FormattedMessage style={{ padding: 0 }} id="hero_title2B" defaultMessage="Transformation with" />
                                        </Typography>
                                    </div>

                                    <Typography sx={{ textAlign: 'justify', paddingTop: 5 }} fontFamily='Montserrat' fontStyle='italic' fontWeight='medium' fontSize={'16pt'} color="inherit" style={{ width: '100%' }} paragraph>
                                        <FormattedMessage id="hero_title3" defaultMessage="a new focus on PROCESSES, PRODUCTS and STRATEGIES of companies and people." />

                                    </Typography>

                                    <Typography sx={{ textAlign: 'justify', paddingTop: 5 }} fontFamily='Signika' fontWeight='light' fontSize={'12pts'} variant="h5" color="inherit" paragraph>
                                        <FormattedMessage id="hero_description" defaultMessage="We are a systems consultancy that specializes in the development of quality software and services, with technology, infrastructure and IT resources of high capacity and extensive experience." />

                                    </Typography> 
                                    */}

                                </Box>

                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>

                                <Stack
                                    sx={{ pt: '1cm', pb: '1.4cm', }}
                                    direction="row"
                                    spacing={2}
                                    justifyContent='space-evenly'
                                    alignItems='center'
                                >

                                    <Box sx={{ flexGrow: 0.5 }} />
                                    <Fab xs={12} aria-label='Ir arriba' sm={6} size="small" color={isMobile ? 'transparent' : 'tertiary'} sx={{ boxShadow: isMobile ? 'unset' : '', bottom: 10, right: 10, position: 'fixed', }} >
                                        <Box component="a" href='#' sx={{ textDecoration: 'none', color: !isMobile ? '#fff' : '#034a9690', }}>
                                            <NavigationIcon sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', fontSize: 20, }} />
                                        </Box>
                                    </Fab>
                                    <Box sx={{ flexGrow: 0.5 }} />
                                    <Fab
                                        component="button"
                                        onClick={openWhatsApp}
                                        aria-label="whatsapp"
                                        size="large"
                                        color={'success'}
                                        sx={{ color: '#fff', bottom: 60, right: 10, position: 'fixed', }}>
                                        <WhatsAppIcon sx={{ fontSize: isMobile ? 35 : 50 }} />
                                    </Fab>

                                </Stack>
                            </Grid>
                        </Grid>


                    </Stack>
                    <Grid item sx={{ display: 'flex', justifyContent: 'center', mt: isMobile ? 20 : 70 }}>
                        <Fab
                            sx={{
                                borderRadius: 25,
                                px: 10,
                                py: 3,
                                background: 'linear-gradient(to right, #e4effa, #6b3fdf)',
                                color: '#fff',
                                fontSize: 12,
                                mt: 12,
                                // position: 'fixed',
                            }}
                            size="large"
                            component="a"
                            href='#calendar'
                            aria-label="Get Started"
                            onClick={() => { }}
                        // >Get&nbsp;Started</Fab>
                        ><FormattedMessage id="hero_title4" defaultMessage=" " />
                            &nbsp;<FormattedMessage id="hero_title5" defaultMessage=" " /> </Fab>
                    </Grid>
                </Box >

            </Container >
        </>
    )
}

export default Hero