import React, { useState } from 'react';
import langEs from '../lang/es.json';
import langEn from '../lang/en.json';

const langContext = React.createContext({});

const LangProvider = ({ children }) => {
    const [rotulos, setRotulos] = useState(langEs);
    const [locale, setlocale] = useState('es-AR');

    const selectLang = (lang) => {
        switch (lang) {
            case 'es-AR':
                // window.location.replace('');
                // window.location.href = window.location.href;

                setRotulos(langEs);
                setlocale('es-AR');
                break;
            case 'en-US':
                // window.location.replace('');
                // window.location.href = window.location.href;

                setRotulos(langEn);
                setlocale('en-US');
                break;
            default:
                setRotulos(langEs);
                setlocale('es-AR');
        }
    }

    return (
        <langContext.Provider value={{ selectLang: selectLang, rotulos: rotulos, locale: locale }}>
            {children}
        </langContext.Provider>
    )
}

export { LangProvider, langContext }; 