import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Avatar, AvatarGroup, Backdrop, Box, Button, CircularProgress, Grid } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import React, { useContext, useState } from 'react';
import { langContext } from '../context/langContext';
import { FormattedMessage } from 'react-intl';


const Menu = () => {
    const isMobile = window.innerWidth <= 768;
    const isTablet = window.innerWidth <= 920;

    const [menuItems] = useState([
        { text: <FormattedMessage id="menu_home" defaultMessage="" />, href: '#hero' },
        { text: <FormattedMessage id="menu_about" defaultMessage="" />, href: '#about' },
        { text: <FormattedMessage id="menu_solutions" defaultMessage="" />, href: '#technologies' },
        { text: <FormattedMessage id="menu_training" defaultMessage="" />, href: '#formations' },
        { text: <FormattedMessage id="menu_contactus" defaultMessage="" />, href: '#calendar' },
    ])


    return (
        <>
            {
                !isMobile
                &&
                <Grid container sx={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'center', textAlign: 'center', }}>
                    {menuItems.map((item, index) => (
                        <Button
                            key={index}
                            component="a"
                            href={item.href}
                            style={{
                                paddingRight: 20,
                                fontSize: isTablet ? '0.45em' : '0.7em',
                                paddingLeft: 12,
                                cursor: 'pointer',
                                textDecoration: 'none',
                                color: '#757575'
                            }}
                            sx={{}}
                        >
                            {item.text}
                        </Button>

                    ))}
                </Grid>
            }
        </>
    );
}

const Navbar = () => {

    const isMobile = window.innerWidth <= 768;

    const { selectLang } = useContext(langContext);


    const [somechg, setSomechg] = useState(false);

    const [isScrolling, setIsScrolling] = useState(false);



    window.onscroll = () => {
        if (document.documentElement.scrollTop < 250)
            setIsScrolling(false)
        else
            setIsScrolling(true)
    }

    window.onscrollend = () => {
        if (document.documentElement.scrollTop < 250)
            setIsScrolling(false)
        else
            setIsScrolling(true)

    }


    const handleClose = () => {
        setTimeout(() => {
            setSomechg(false);
        }, 2000);
        setSomechg(true);
    }

    return (
        <>
            {/* <Container disableGutters maxWidth="xl" > */}

            {somechg ? (
                <Backdrop
                    sx={{
                        backgroundColor: "#00000060",
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={somechg}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <Box sx={{ flexGrow: 1 }}  >
                    <AppBar
                        component="box"
                        position="fixed"
                        elevation={0}
                        sx={{
                            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                            minHeight: 5,
                            mt: isMobile ? 0 : -1.4,
                            maxWidth: '100%', maxHeight: '1.48cm', m: 'auto', background: '#fff'
                        }}
                    >

                        <Toolbar
                        // sx={{ height: '100%', width: '100%', alignSelf: 'center', justifyContent: 'center', alignContent: 'center', gap: 0.2, }}
                        >

                            {/* <Typography variant="h6" color="inherit" sx={{ display: 'flex', gap: 1 }} > */}
                            < Avatar sx={{ bgcolor: 'black', width: 30, height: 30, }}>
                                <Link
                                    color="#fff"
                                    href="https://www.linkedin.com/company/extrados-ar/mycompany/"
                                    target="_blank"
                                    aria-label="linkedin"
                                >
                                    <LinkedInIcon sx={{ height: '1.25rem', width: 'auto' }} />
                                </Link>
                            </Avatar>
                            <Avatar sx={{ bgcolor: 'black', width: 30, height: 30 }}>
                                <Link
                                    color="#fff"
                                    href="https://www.instagram.com/extrados.ar/"
                                    target="_blank"
                                    aria-label="instagram"
                                >
                                    <InstagramIcon sx={{ height: '1.25rem', width: 'auto' }} />
                                </Link>
                            </Avatar>
                            <Avatar sx={{ bgcolor: 'black', width: 30, height: 30 }}>
                                <Link
                                    color="#fff"
                                    href="mailto:info@extrados.ar"
                                    aria-label="email"
                                >
                                    <MailOutlineIcon sx={{ height: '1.25rem', width: 'auto' }} />
                                </Link>
                            </Avatar>
                            {/* </Typography> */}
                            {
                                isMobile &&
                                <Box sx={{ flexGrow: 1 }} />
                            }
                            <Box
                                sx={{ paddingLeft: isMobile ? 0 : 1, flexGrow: 1, height: '3rem', display: 'flex', justifyContent: isMobile ? 'center' : 'start', }}
                                component='a'
                                href={"#hero"}
                                style={{
                                    transition: 'visibility 0.9s, opacity 0.9s linear',
                                    visibility: isScrolling ? 'visible' : 'hidden',
                                    opacity: isScrolling ? 1 : 0,
                                }}
                            >
                                <img
                                    src={'/logo.svg'}
                                    alt='logo'
                                    style={{

                                        height: '100%',
                                        width: 'auto',
                                        objectFit: 'contain',
                                    }}
                                />
                            </Box>


                            <Box sx={{ flexGrow: 1 }} />
                            <Box sx={{}} >
                                <Menu />
                            </Box>
                            <AvatarGroup sx={{}} max={4}>
                                <Avatar
                                    component='button'
                                    sx={{ bgcolor: '#757575', textDecoration: 'none', borderRadius: '50%', width: 20, height: 30, fontSize: 8 }}
                                    href='#'
                                    alt="en-US"
                                    // src="/assets/img/uk.png"
                                    onClick={() => {
                                        handleClose()
                                        selectLang("en-US")
                                    }}
                                >ENG</Avatar>
                                <Avatar
                                    component='button'
                                    sx={{ bgcolor: '#757575', textDecoration: 'none', borderRadius: '50%', width: 20, height: 30, fontSize: 8 }}
                                    href='#'
                                    alt="es-AR"
                                    // src="/assets/img/spain.png"
                                    onClick={() => {
                                        handleClose()
                                        selectLang("es-AR")
                                    }}
                                >ESP</Avatar>
                            </AvatarGroup>
                        </Toolbar>

                    </AppBar >
                </Box>
            )}


            {/* </Container > */}
        </>
    )
}

export default Navbar