import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const About = () => {
    return (
        <>
            <Container maxWidth={'false'}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ pt: '2cm', pb: '4cm', minHeight: '10vh' }}
                >
                    <Grid item xs={6}>
                        <Typography sx={{ width: 1, pt: 1, pb: 5, color: '#034a96', fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '2.4rem', textAlign: 'center' }}>
                            <FormattedMessage id="about_text1" defaultMessage="ABOUT US" />
                        </Typography>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ width: '80%', py: 1, fontFamily: 'Signika', fontWeight: 'light', fontSize: '12pt', textAlign: 'justify', }}>
                                <FormattedMessage id="about_text2" defaultMessage="EXTRADOS Software Technology formally began its activities in" />
                                <Typography component='b' sx={{ fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '12ptos', color: '#6b3fdf' }}>
                                    {' '}
                                    <FormattedMessage id="about_text3" defaultMessage="2008," />
                                </Typography>
                                {' '}
                                <FormattedMessage id="about_text4" defaultMessage="" />
                                {/* <FormattedMessage id="about_text5" defaultMessage="" />
                                <FormattedMessage id="about_text6" defaultMessage="" /> */}

                                <Typography component='b' sx={{ fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '12ptos', color: '#6b3fdf' }}>
                                    {' '}<FormattedMessage id="about_text5" defaultMessage="" />
                                    {' '}<FormattedMessage id="about_text6" defaultMessage="" />
                                </Typography>
                            </Typography>
                            <Typography sx={{ width: '80%', py: 1, fontFamily: 'Signika', fontWeight: 'light', fontSize: '12ptos', textAlign: 'justify' }}>
                                <FormattedMessage id="about_text7" defaultMessage="" />

                                <Typography component='b' sx={{ fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '12ptos', color: '#6b3fdf' }}>
                                    {' '}  <FormattedMessage id="about_text8" defaultMessage="" />
                                </Typography>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default About