import * as React from 'react';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
//Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./stylesparticipations.module.css";
// import required modules
import { Autoplay, Pagination } from "swiper/modules";
// import required modules
import { Navigation } from "swiper/modules";
import useScreenSize from '../../../../helpers/useScreenSize';
import { FormattedMessage } from 'react-intl';

export function Slider() {
    const { width } = useScreenSize();
    return (
        <>
            <Swiper
                navigation={true}
                slidesPerView={width > 920 ? 3 : 1}
                spaceBetween={20}
                centeredSlides={true}
                pagination={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}

                className="mySwiper"
            >
                {images.map((step, index) => (
                    <div key={index}>
                        <SwiperSlide key={step.id}>
                            <Box
                                component="img"
                                sx={{
                                    height: !isMobile ? '60vh' : '100vh',
                                    overflow: 'hidden',
                                    width: '100%',
                                    objectFit: 'cover',

                                }}
                                src={step.imgPath}
                                alt={step.label}
                            />
                        </SwiperSlide>

                    </div>
                ))}
            </Swiper>
        </>
    );
}


const images = [
    {
        id: 1,
        label: 'miami6',
        imgPath: '/assets/img/miami6.webp'
    },
    {
        id: 2,
        label: 'Feria MWC. Barcelona 2023.',
        imgPath: '/assets/img/barcelona.webp'
    },

    {
        id: 3,
        label: 'Córdoba Argentina',
        imgPath: '/assets/img/cba.webp'
    },
    {
        id: 4,
        label: 'miami1',
        imgPath: '/assets/img/miami1.webp'
    },
    {
        id: 5,
        label: 'stand5',
        imgPath: '/assets/img/stand5.webp'
    },
    {
        id: 6,
        label: 'miami2',
        imgPath: '/assets/img/miami2.webp'
    },
    {
        id: 7,
        label: 'barcelona1',
        imgPath: '/assets/img/barcelona1.webp'
    },

    {
        id: 8,
        label: 'mochila',
        imgPath: '/assets/img/mochila.webp'
    },

    {
        id: 9,
        label: 'caminata1',
        imgPath: '/assets/img/caminata1.webp'
    },
    {
        id: 10,
        label: 'stand1',
        imgPath: '/assets/img/stand1.webp'
    },
    {
        id: 11,
        label: 'caminata2',
        imgPath: '/assets/img/caminata2.webp'
    },
    {
        id: 12,
        label: 'stand2',
        imgPath: '/assets/img/2.webp'
    },
    {
        id: 13,
        label: 'cba01',
        imgPath: '/assets/img/cba01.webp'
    },
    {
        id: 14,
        label: 'stand3',
        imgPath: '/assets/img/stand3.webp'
    },
    {
        id: 16,
        label: 'juntas1',
        imgPath: '/assets/img/juntas1.webp'
    },
    {
        id: 17,
        label: 'stand4',
        imgPath: '/assets/img/stand4.webp'
    },


];


const isMobile = window.innerWidth <= 768;
export function StandardImageList() {
    return (
        <ImageList sx={{ width: !isMobile ? '60vw' : '98vw', m: 'auto', overflow: 'hidden' }} cols={3} rowHeight={250}>
            {itemData.map((item) => (
                <ImageListItem sx={{ maxHeight: 350, maxWidth: 250, display: 'flex', alignItems: 'center', alignSelf: 'center', justifyContent: 'center', alignContent: 'center' }} key={item.img}>

                    <img
                        srcSet={`${item.img}?w=184&h=184&fit=cover&auto=format&dpr=2 2x`}
                        src={`${item.img}?w=184&h=184&fit=cover&auto=format`}
                        alt={item.title}
                        loading="lazy"
                        style={{ height: '80%', maxWidth: !isMobile ? '80%' : '100%', objectFit: 'cover' }}
                    />

                    <Typography style={{ height: '20%', fontSize: !isMobile ? '10pt' : '8pt', color: '#034a96' }}>{item.title.toUpperCase()}</Typography>
                </ImageListItem>
            ))}
        </ImageList>
    );
}

const itemData = [

    {
        id: 3,
        alt: 'Equipo Colaboradores Córdoba',
        title: 'Córdoba Argentina',
        img: '/assets/img/cbatiny.webp'
    },
    {
        id: 1,
        alt: 'Feria MWC Barcelona 2023',
        title: 'Feria MWC. Barcelona 2023',
        img: '/assets/img/barcelonatiny.webp'
    },
    {
        id: 2,
        alt: 'Feria Emergeamericas Miami 2023',
        title: 'Emergeamericas Miami 2023',
        img: '/assets/img/miamitiny.webp'
    },

];

const Participations = () => {
    const { width } = useScreenSize();
    return (
        <Container disableGutters maxWidth="false" component="main" sx={{ my: 5 }}>

            <Box sx={{ display: 'flex', px: width > 920 ? 2 : 1, flexDirection: 'column', gap: '0.7cm' }}>
                <Typography
                    component="h2"
                    align="center"
                    gutterBottom
                    sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '20pt', color: '#034a96' }}
                >
                    <FormattedMessage id="participations_text1" defaultMessage="" />

                    {/* PARTICIPACIONES */}
                </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
                <Slider />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignSelf: 'center', alignItems: 'center', pt: 3 }}>
                <StandardImageList />
            </Box>

        </Container>
    )
}


export default Participations