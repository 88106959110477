import React, { useContext } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "../App";
import { langContext } from "../context/langContext";
import { Privacy } from "../pages/privacy/index";

export const AppRoutes = () => {
  const { rotulos, locale } = useContext(langContext);

  return (
    <IntlProvider locale={locale} messages={rotulos}>
      <BrowserRouter basename="/">
        <Routes>
          <Route exact path="/" element={<App />} />

          <Route exact path="/privacy" element={<Privacy />} />

          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </BrowserRouter>
    </IntlProvider>
  );
};
