import React from 'react';
import { Box, Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';


export const LeftCard = () => {
    return (
        <>
            <CardContent sx={{ minHeight: '100%', bgcolor: '#e4effa', display: 'flex', alignItems: 'center' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        width: '100%',
                        height: 'auto',


                    }}
                >
                    <Box sx={{ py: 0, }}>
                        <Typography component='div' sx={{ textAlign: 'justify', display: 'flex', gap: 1.5, flexDirection: 'column' }} align="center">

                            <Typography component='div' sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 24, color: '#034a96' }} align="center">
                                {' '}<FormattedMessage id="career_text2" defaultMessage="" />
                                {/* BENEFICIOS PARA VOS */}
                            </Typography>

                            <Typography component='div' sx={{ fontFamily: 'Signika', fontWeight: 'bold', fontSize: '12pts', color: '#6b3fdf' }}>
                                {'>> '}
                                <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '12pts', color: '#1A2027' }} >
                                    {' '}<FormattedMessage id="career_text3" defaultMessage="" />
                                    {/* Obra social de primer nivel. */}
                                </Typography>
                            </Typography>
                            <Typography component='div' sx={{ fontFamily: 'Signika', fontWeight: 'bold', fontSize: '12pts', color: '#6b3fdf' }}>
                                {'>> '}
                                <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '12pts', color: '#1A2027' }}>
                                    {' '}<FormattedMessage id="career_text4" defaultMessage="" />
                                    {/* Horarios flexibles. */}
                                </Typography>
                            </Typography>
                            <Typography component='div' sx={{ fontFamily: 'Signika', fontWeight: 'bold', fontSize: '12pts', color: '#6b3fdf' }}>
                                {'>> '}
                                <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '12pts', color: '#1A2027' }}>
                                    {' '}<FormattedMessage id="career_text5" defaultMessage="" />
                                    {/* After office. */}
                                </Typography>
                            </Typography>
                            <Typography component='div' sx={{ fontFamily: 'Signika', fontWeight: 'bold', fontSize: '12pts', color: '#6b3fdf' }}>
                                {'>> '}
                                <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '12pts', color: '#1A2027' }}>
                                    {' '}<FormattedMessage id="career_text6" defaultMessage="" />
                                    {/* Festejo fin de año. */}
                                </Typography>
                            </Typography>
                            <Typography component='div' sx={{ fontFamily: 'Signika', fontWeight: 'bold', fontSize: '12pts', color: '#6b3fdf' }}>
                                {'>> '}
                                <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '12pts', color: '#1A2027' }}>
                                    {' '}<FormattedMessage id="career_text7" defaultMessage="" />
                                    {/* Gif card por cumpleaños. */}
                                </Typography>
                            </Typography>
                            <Typography component='div' sx={{ fontFamily: 'Signika', fontWeight: 'bold', fontSize: '12pts', color: '#6b3fdf' }}>
                                {'>> '}
                                <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '12pts', color: '#1A2027' }}>
                                    {' '}<FormattedMessage id="career_text8" defaultMessage="" />
                                    {/* Cursos de Udemy. */}
                                </Typography>
                            </Typography>
                            <Typography component='div' sx={{ fontFamily: 'Signika', fontWeight: 'bold', fontSize: '12pts', color: '#6b3fdf' }}>
                                {'>> '}
                                <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '12pts', color: '#1A2027' }}>
                                    {' '}<FormattedMessage id="career_text9" defaultMessage="" />
                                    {/* Certificaciones. */}
                                </Typography>
                            </Typography>
                            <Typography component='div' sx={{ fontFamily: 'Signika', fontWeight: 'bold', fontSize: '12pts', color: '#6b3fdf' }}>
                                {'>> '}
                                <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '12pts', color: '#1A2027' }}>
                                    {' '}<FormattedMessage id="career_text10" defaultMessage="" />
                                    {/* Trabajo modalidad presencial/remota. */}
                                </Typography>
                            </Typography>
                            <Typography component='div' sx={{ fontFamily: 'Signika', fontWeight: 'bold', fontSize: '12pts', color: '#6b3fdf' }}>
                                {'>> '}
                                <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '12pts', color: '#1A2027' }}>
                                    {' '}<FormattedMessage id="career_text11" defaultMessage="" />
                                    {/* Descuentos en Club la Voz. */}
                                </Typography>
                            </Typography>
                            <Typography component='div' sx={{ fontFamily: 'Signika', fontWeight: 'bold', fontSize: '12pts', color: '#6b3fdf' }}>
                                {'>> '}
                                <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '12pts', color: '#1A2027' }}>
                                    {' '}<FormattedMessage id="career_text12" defaultMessage="" />
                                    {/* Cursos de inglés. */}
                                </Typography>
                            </Typography>
                            {/* <Typography component='div' sx={{ fontFamily: 'Signika', fontWeight: 'bold', fontSize: '12pts', color: '#6b3fdf' }}>
                                {'>> '}
                                <Typography component='div' sx={{ display: 'inline', fontFamily: 'Signika', fontWeight: 'semibold', fontSize: '12pts', color: '#1A2027' }}>
                                    {' '}<FormattedMessage id="career_text13" defaultMessage="" />
                                    {'Gimnasios.'}
                                </Typography>
                            </Typography> */}
                        </Typography>
                    </Box>
                </Box>

            </CardContent>

        </>
    )
}

export const RightCard = () => {

    return (
        <>
            <CardMedia
                component="img"
                image='/assets/img/worker.webp'
                alt="worker"
                sx={{ minHeight: 430, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', objectFit: 'contain', }}
            />
        </>
    )
}

const Work = () => {

    const cards = [
        {
            "title": "left",
            "component": <LeftCard />
        },
        {
            "title": "right",
            "component": <RightCard />
        },
    ]

    return (
        <>
            <Container disableGutters maxWidth="false" component="main" sx={{ pt: '1.5cm', pb: '0.7cm' }}>
                <Typography
                    component="h2"
                    align="center"
                    gutterBottom
                    sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '2.4rem', color: '#034a96' }}
                >
                    {' '}<FormattedMessage id="career_text1" defaultMessage="" />

                    {/* ¿QUERES TRABAJAR CON NOSOTROS? */}
                </Typography>

            </Container>

            <Container maxWidth="xl" sx={{ pt: 0, pb: '1.2cm' }}>
                <Grid container spacing={1} sx={{ justifyContent: 'space-evenly' }} alignItems="flex-center">
                    {cards.map(card => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid
                            item
                            key={card.title}
                            xs={12}
                            md={5.5}
                            sm={5.5}
                        >
                            <Card sx={{ height: 470, minWidth: 'auto' }}>
                                {card.component}
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container >
        </>

    )
}

export default Work