import Typography from '@mui/material/Typography';
import React from 'react';
// import Link from '@mui/material/Link';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Container, Grid, Link } from '@mui/material';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';
import { WhatsApp } from '@mui/icons-material';

function Copyright() {
    return (
        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: isMobile ? '8pt' : '12pt', color: '#034a96' }} variant="body2" color="text.secondary" align="center">
            {'© '}
            {/* <Link color="inherit" href="https://mui.com/">
                https://extrados.ar
            </Link>{' '} */}
            {new Date().getFullYear()}
            {' '}<FormattedMessage id="footer_text2" defaultMessage="" />
            {/* {' EXTRADOS Software Technology. '} */}
            <Typography
                sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: isMobile ? '8pt' : '12pt', color: '#034a96' }}
                display="inline"
                variant="caption"
                align="center"
                color="text.secondary"
            >
                {' '}<FormattedMessage id="footer_text3" defaultMessage="" />
                {/* Todos los derechos reservados. */}
                <Box component="a" target="_parent" href="/privacy">
                    Privacy Policy
                </Box>
            </Typography>
        </Typography>
    );
}
const isMobile = window.innerWidth <= 768;
function openWhatsApp() {
    const phoneNumber = '5493512597577'; // Número de teléfono
    const message = encodeURIComponent('*Hola: me gustaría solicitar info sobre...*'); // Mensaje

    const isMobileDev = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    let url = '';
    if (isMobile || isMobileDev) {
        url = `whatsapp://send?phone=${phoneNumber}&text=${message}`;
    } else {
        url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
    }

    window.open(url, '_blank');
}

const Footer = () => {
    // let navigate = useNavigate();

    return (
        <>
            {/* Footer */}
            <Container sx={{}} disableGutters maxWidth="false">

                <Grid sx={{ bgcolor: '#e4effa', pb: '0.7cm' }}>
                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}  >
                        <Grid item xs={12} sm={6} md={6} sx={{ px: '1cm', display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ mt: 5, fontFamily: 'Signika', fontWeight: 'light', fontSize: isMobile ? '10pt' : 20, textAlign: 'justify' }}>
                                {' '}<FormattedMessage id="footer_text1" defaultMessage="" />
                                {/* Capacita, potencia y actualiza sus profesionales mediante cursos,
                                talleres y seminarios asegurando de esta manera un servicio acorde a
                                las necesidades particulares de cada cliente y acorde al creciente
                                avance tecnológico del mercado. */}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{ p: '1cm', display: 'flex', alignItems: 'center' }}>
                            <Box
                                component="img"
                                sx={{
                                    maxHeight: 350,
                                    display: 'block',
                                    maxWidth: '99vw',
                                    overflow: 'hidden',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',

                                }}
                                src={'/assets/img/logo3.webp'}
                                alt={'logo3'}
                            />

                        </Grid>
                    </Grid>

                    <Grid container >
                        <Box
                            component="img"
                            sx={{
                                maxHeight: 350,
                                display: 'flex',
                                maxWidth: '99vw',
                                alignSelf: 'center',
                                overflow: 'hidden',
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',

                            }}
                            src={'/assets/img/divider.svg'}
                            alt={'logocluster'}
                        />
                    </Grid>
                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', py: '1.2cm' }} >
                        <Grid item xs={12} sm={2} md={2} sx={{}} />
                        <Grid item xs={12} sm={4} md={4} sx={{ p: 0 }}>

                            <Typography variant={'body2'} sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'left', alignContent: 'center', gap: 1, my: 3, fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '13pt', color: '#034a96' }}>
                                <Box component="strong" sx={{ display: 'flex', alignSelf: 'center', borderRadius: '50%', bgcolor: '#fff', p: 0.5 }}>
                                    <InstagramIcon sx={{ height: '1.25rem', width: 'auto' }} />
                                </Box>
                                <Link
                                    color="#034a96"
                                    href="https://www.instagram.com/extrados.ar/"
                                    target="_blank"
                                >
                                    @extrados.ar
                                </Link>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={2} md={2} sx={{}} />

                        <Grid item xs={12} sm={4} md={4} sx={{ p: 0 }}>
                            <Typography variant={'body2'} sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'left', alignContent: 'center', gap: 1, my: 3, fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '13pt', color: '#034a96' }}>
                                <Box component="strong" sx={{ display: 'flex', alignSelf: 'center', borderRadius: '50%', bgcolor: '#fff', p: 0.5 }}>
                                    <WhatsApp sx={{ height: '1.25rem', width: 'auto' }} />
                                </Box>
                                <Box onClick={openWhatsApp}>
                                    +54 9 351 259 7577
                                </Box>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={2} md={2} sx={{}} />

                        <Grid item xs={12} sm={4} md={4} sx={{ p: 0 }}>
                            <Typography variant={'body2'} sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'left', alignContent: 'center', gap: 1, my: 3, fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '13pt', color: '#034a96' }}>
                                <Box component="strong" sx={{ display: 'flex', alignSelf: 'center', borderRadius: '50%', bgcolor: '#fff', p: 0.5 }}>
                                    <LinkedInIcon sx={{ height: '1.25rem', width: 'auto' }} />
                                </Box>
                                <Link
                                    color="#034a96"
                                    href="https://www.linkedin.com/company/extrados-ar/mycompany/"
                                    target="_blank"
                                >
                                    Extrados Software Technology
                                </Link>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={2} md={2} sx={{}} />

                        <Grid item xs={12} sm={4} md={4} sx={{ p: 0 }}>
                            <Typography variant={'body2'} sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'left', alignContent: 'center', gap: 1, my: 3, fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '13pt', color: '#034a96' }}>
                                <Box component="strong" sx={{ display: 'flex', alignSelf: 'center', borderRadius: '50%', bgcolor: '#fff', p: 0.5 }}>
                                    <MailOutlineIcon sx={{ height: '1.25rem', width: 'auto' }} />
                                </Box>
                                <Link
                                    color="#034a96"
                                    href="mailto:info@extrados.ar"
                                    target="_blank"
                                >
                                    info@extrados.ar
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Box sx={{ bgcolor: 'background.paper', py: isMobile ? 0 : 1 }} component="footer">
                    <Copyright />
                </Box>
            </Container>
            {/* End footer */}
        </>
    )
}

export default Footer